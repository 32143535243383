html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

root {
    height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Make nav button font smaller to stop wrapping */
.nav-item {
    font-size: 18px !important;
}

.InfiniteCarouselDotIcon {
    border: thin solid darkslategray;
}

.rc-slider-handle:active {
    box-shadow: 0 0 0 4px #999999 !important;
    border-color: #299FD6 !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 4px #999999 !important;
    border-color: #299FD6 !important;
}

.alice-carousel__dots {
    margin-top: 10px !important;
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
    color: unset;
}

.alice-carousel__stage-item {
    text-align: center;
}

.dropdown-menu {
    height: auto;
    max-height: 275px;
    overflow-x: hidden;
    border: gray solid thin;
}

.modal-90w {
    max-width: 90%;
}

.modal-80w {
    max-width: 80%;
}

.modal-70w {
    max-width: 70%;
}

.modal-60w {
    max-width: 60%;
}

.metadata-modal-open .metadata-modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.metadata-modal {
    z-index: 2100;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.metadata-modal-backdrop.fade {
    opacity: 0;
}

.metadata-modal-backdrop.show {
    opacity: 0.5;
}

.metadata-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.placeholder {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: white;
}

.filterStyleButton {
    background: #201F20;
    color: #F7AE00;
    border-color: #F7AE00;
}

.filterStyleButton:hover {
    background: rgba(247, 174, 0, 0.75) !important;
    color: #201F20;
    border-color: #201F20;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(247 174 0 / 25%);
}

.filterStyleButton:active {
    background: #F7AE00 !important;
    color: #201F20 !important;
    border-color: #201F20 !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(247 174 0 / 25%);
}

.filterStyleButton:focus {
    background: #F7AE00 !important;
    color: #201F20 !important;
    border-color: #201F20 !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(247 174 0 / 25%);
}

.navbar-nav .dropdown-menu {
    z-index: 9999;
}

.sticky-banner {
    position: sticky;
    z-index: 20;
}

.brandSwitch label {
    width: 100px;
    text-align: left;
}

.rbt-close-content {
    color: black;
}